import { toast } from "react-toastify";

const toastConfig = {
  position: "top-center",
  autoClose: 1200,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: false,
};

const showToast = (message, type) => {
  return toast[type](message, toastConfig);
};

export default showToast;
