import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteBlogByID,
  getAllBlogs,
  getBlogByID,
  updateBlog,
} from "../api/functions/posts";
import showToast from "../components/showToast";
import moment from "moment";
import Deleteblog from "../components/blog/deleteModal";
import TopHeader from "../components/TopHeader";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Home = () => {
  const navigate = useNavigate();
  const { blogs, setBlogs: setBlogsContext, userData } = useContext(AppContext);

  const fetchBlogsData = async () => {
    const res = await getAllBlogs();
    if (res.status === 200) {
      setBlogsContext(res.data.posts);
    }
  };

  useEffect(() => {
    fetchBlogsData();
  }, []);

  const [blogObject, setBlogObject] = useState({});
  const getBlogObject = async (e, blog) => {
    e.preventDefault();

    const res = await getBlogByID(blog.id);
    if (res.status === 200) {
      setBlogObject(res.data.blog);
    }
  };

  const handleEditData = async () => {
    // const res = await ;
  };

  return (
    <>
      <TopHeader />
      <Deleteblog blog={blogObject} fetchBlogsData={fetchBlogsData} />
      <main className="main-content">
        <div className="container-fluid">
          <div className="blogsLists">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                <h1>Hello {userData?.name?.toUpperCase()}!</h1>

                <div className="row">
                  {blogs?.map((blog, index) => (
                    <div className="card overflow-hidden blog-card" key={index}>
                      <Link to={`/blog/${blog.id}`} className="cover-image">
                        <img
                          className="card-image"
                          src={`${IMAGE_URL}/${blog.photo}`}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "placeholder_image_url";
                          }}
                        />
                      </Link>
                      <div className="card-body">
                        <div className="card-main-content">
                          <h4 className="card-title">{blog.title.en}</h4>
                          <span>
                            Created At:{" "}
                            {moment(blog.createdAt).format("MMMM Do YYYY, h a")}
                          </span>
                          <span>
                            Last Update At:{" "}
                            {moment(blog.updatedAt).format("MMMM Do YYYY, h a")}
                          </span>
                        </div>
                        <div className="buttons">
                          <div
                            className="action-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteblogModal"
                            onClick={(e) => getBlogObject(e, blog)}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              fontSize={30}
                              color={"#e84c4f"}
                            />
                          </div>
                          <div className="action-btn">
                            <FontAwesomeIcon
                              icon={faFileEdit}
                              fontSize={30}
                              color={"#2ecc71"}
                              onClick={() => navigate(`/edit/${blog.id}`)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <hr className="gray_line" />
                <div className="row">
                  <div className="col-md-6">
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
