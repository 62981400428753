import React from "react";
import showToast from "../showToast";
import { deleteBlogByID } from "../../api/functions/posts";

const Deleteblog = ({ blog, fetchBlogsData }) => {
  const handleDeleteBlog = async (e, id) => {
    e.preventDefault();
    try {
      const res = await deleteBlogByID(id);
      if (res.status === 200) {
        showToast(res.data.message, "error");
        fetchBlogsData();
      }
    } catch (err) {
      showToast(err.response.data.message, "error");
      console.error(err);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="deleteblogModal"
        tabIndex="-1"
        aria-bs-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={(e) => handleDeleteBlog(e, blog?._id)}>
              <div className="modal-body">
                <h5 className="modal-title" id="deleteblogModalLabel">
                  Are you sure you want to delete this blog: {blog?.blogName}
                </h5>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deleteblog;
